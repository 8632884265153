import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import FooterFunction from "../../components/app/functions/FooterFunction";
import React, {useEffect, useState} from "react";
import {ReactComponent as PersonIcon} from '../../assets/person-img.svg';
import {
    claimPoints, formatTime,
    getPerson,
    getEarnings, getRandomElement,
    getReferrals,
    getTasks,
    parseNumbers
} from "../../logic/server/api";
import {toast, ToastContainer} from "react-toastify";
import {useSpring, useSpringRef} from "@react-spring/web";
import {
    animated,
} from '@react-spring/web';
import PersonInfoFunction from "../../components/app/functions/PersonInfoFunction";
import {ReactComponent as WatchI} from '../../assets/stopwatch2.svg';
import {ReactComponent as StarsIcon} from '../../assets/stars.svg';
import {useSelector} from "react-redux";
import {ACTIVE_TIME} from "../../logic/server/Variables";
import person1 from "../../assets/person1.png";
import person2 from "../../assets/person2.png";
import person3 from "../../assets/person3.png";
import person4 from "../../assets/person3.png";
import person5 from "../../assets/person5.png";
import person6 from "../../assets/person6.png";
import person7 from "../../assets/person7.png";
import {useLocation} from "react-router-dom";
// import {TelegramIcon, TelegramShareButton} from "react-share";

const EarningsPage = () => {
    const location = useLocation();
    const [earnings, setEarnings] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [referralsTasks, setReferralsTasks] = useState([]);
    const [currentTask, setCurrentTask] = useState({});
    const [taskClaimed, setTaskClaimed] = useState([]);
    const [person, setPerson] = useState({customer_id: null, token: null});
    const [time, setTime] = useState(0);
    const [editState, setEditState] = useState(false);
    const count = useSelector(state => state.counter.count);
    const persons = [person1, person2, person3, person4, person5, person6, person7];
    // animation
    const springApi = useSpringRef()
    const [props, api] = useSpring(
        () => ({
            from: { opacity: 0.2 },
            to: { opacity: 1 },
            config: {
                duration: 1000,
            },
            ref: springApi
        }),
        []
    )

    useEffect(() => {
        getPersonFetch(location?.state?.isOpened);
    }, []);

    useEffect(() => {
        getTasksRequest();
        getReferralsList();
        springApi.start();
    }, [taskClaimed]);

    async function getPersonFetch(status) {
        const resp = await getPerson();
        if (resp && resp.status) {
            setPerson(resp?.person);
            setTime(localStorage.getItem('time') && (Number(localStorage.getItem('time')) <= ACTIVE_TIME) ? Number(localStorage.getItem('time')) : ACTIVE_TIME);
            setEditState(status);
        }
    }


    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';
    const shareUrl = `https://t.me/share/url?url=https://t.me/PicklezoneGameBot/PicklezoneGame` + code + `&text=Join Picklezone Game!`;
    const copyLink = `https://t.me/PicklezoneGameBot/PicklezoneGame` + code;
    async function getTasksRequest() {
        const resp = await getTasks();
        if (resp?.status) {
            const referralTasks = resp?.tasks?.sort((a, b) => a?.conditions[0]?.data?.number - b?.conditions[0]?.data?.number).
            filter(task => task?.conditions[0]?.type === 'referral-number' && (task?.completion?.status !== 'completed'))
            setReferralsTasks(referralTasks?.length ? referralTasks : []);
            setCurrentTask(referralTasks[0]);
            // console.log('tasks', resp);
        }
    }

    async function onClaimTask(task) {
        const res = await claimPoints( localStorage.getItem('token'), task.id);
        if (res?.status) {
            setTaskClaimed([{id: task.id, status: true}]);
            toast.success(`You claimed ${task?.reward?.data?.value} DINKS successfully!`);
        }
    }

    async function getReferralsList() {
        const resp = await getReferrals();
        if (resp?.status) {
            const referralsSorted = resp?.referrals.sort((a, b) => b?.affiliate_programs[0].reward?.loyalty_points - a?.affiliate_programs[0].reward?.loyalty_points);
            setReferrals(referralsSorted);
        }
        console.log(resp);
    }


    function openLink() {
        window.open(shareUrl);
    }

    async function onCopyLink() {
       await navigator.clipboard.writeText(copyLink);
    }

    function showError(error) {
        toast.error(error);
    }

    const generateShareUrl = () => {
        return `share/url?url=https://t.me/PZN_DEV_BOT/game`;
    };


    return (
        <TelegramScreen
            className={`main-wrapper white-background`}  id='gesuredZone'>

            <div className='picklezone-container picklezone-container-dim'>
                {editState && (<div className={'person-info__bg'}></div>)}

                <div className={'referral__person-container'}>
                    <PersonInfoFunction  person={person}
                                         editState={editState}
                                         onEditModeChanged={(mode) => setEditState(mode)}
                                         onErrorForm={(text) => text ? showError(text) : ''}
                                         onUpdateCustomer={() => getPersonFetch(false)}
                                         page={'referrals'} ></PersonInfoFunction>
                </div>
                <div className={`referral__cards-container ${!editState ? 'ahead' : ''}` }>
                    <div className={'referral__card'}>
                        <StarsIcon/>
                        <div className={'column'}>
                           <p className={'person-info__link'}>Dinks held</p>
                           <p className={'person-info__text'}>{parseNumbers(count)}</p>
                        </div>
                        </div>
                    <div className={'referral__card'}>
                        <WatchI/>
                       <div className={'column'}>
                           <p className={'person-info__link'}>time played</p>
                           <p className={'person-info__text'}>{formatTime(time)}</p>
                       </div>
                    </div>
                </div>
                <div className={`cards-container--referral ${!editState ? 'ahead' : ''}`}>
                    <div className={'earnings-card__row'}>
                        <p  className={'person-info__text-title'}>friends</p>
                        {!currentTask?.completion?.claimable &&
                            <a className={'person-info__link'}
                                                                   onClick={() => openLink()}>invite more</a>}
                        {currentTask?.completion?.claimable  && (
                            <button className={`person-info__link`} style={{color: '#0A8372'}}
                             onClick={() => onClaimTask(currentTask)}>CLAIM NOW
                            </button>)}
                    </div>
                    <div className={'container-scrollable earnings'}>
                    {referrals.map((referral, i) => (
                        <div className={'earning-card__task'}
                             key={i}
                        >
                            <div className={'earning-card-block'}>
                                <img   className={'earning__img'} src={referral?.profile?.image ? referral?.profile?.image : getRandomElement(persons)}/>
                                <div className={'column'}>
                                    <p className={'text-name-roboto'}>{referral?.profile?.name}</p>
                                    <p className={'earning__text-accent'}>{referral?.affiliate_programs[0].reward?.loyalty_points} DINKS</p>
                                </div></div>
                        </div>
                    ))}
                    </div>
                    </div>

                <div className='forms__bottom'>
                    {/*<TelegramShareButton url={generateShareUrl()} title={`Invite more friends`}>*/}
                    {/*    <TelegramIcon size={32}/>*/}
                    {/*</TelegramShareButton>*/}
                    <div className={'picklezone-forms-wrapper'}>

                    <FooterFunction currentPage={'earnings'}/>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}

                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)


}

export default EarningsPage;
