export const API_URL = 'https://dev.em.techfunder.de';
export const BRN_URL = 'https://api.tapcat.fun';
// export const BOT_ID = 'KY24L16X12'; //realty
export const BOT_ID = 'SEFP66REU3';
export const MERCHANT_ID = '12JTPLDN3H';
export const TASK_ID = '66f2d1f9f7644359636d3640';

export const SlEEP_TIME = 5 * 60 * 1000;
export const ACTIVE_TIME = 10 * 60 * 1000;


export const APP_VERSION = '1.0.5';

export const LEADERBOARD_KEY = '679e10c4d7a934d548deb68b';

export const COLLECTION_ID = 'JKAZLSDX6O';
