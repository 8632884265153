import React, { useState } from 'react';
import Person from "../../../assets/person1.png";

const ImageComponent = ({ src, alt, imgClass }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const fallbackSrc = Person;

  const handleError = () => {
    setImgSrc(fallbackSrc);
  };

  return (
    <img
      className={imgClass}
      src={imgSrc}
      alt={alt}
      onError={handleError}
    />
  );
};

export default ImageComponent;
