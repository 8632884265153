import React, {useEffect, useState} from "react";

import Person from "../../../assets/person1.png";
import {ReactComponent as WatchI} from '../../../assets/stopwatch2.svg';
import {ReactComponent as StarsIcon} from '../../../assets/stars.svg';
import {ReactComponent as FriendsIcon} from '../../../assets/friends2.svg';
import {ReactComponent as CloseIcon} from '../../../assets/person-close.svg';

import {useNavigate} from "react-router-dom";
import {useTelegram} from "../../../hooks/useTelegram";
import {useSelector} from "react-redux";
import {changeCustomerData, getPerson, parseNumbers, updatePerson, uploadImage} from "../../../logic/server/api";
import ImageComponent from "./ImageComponent";
import person1 from "../../../assets/person1.png";
import {ReactComponent as SettingsIcon} from '../../../assets/profile-settings.svg';
import {PATH_EARNED} from "../../../constants/Paths";

const PersonInfoFunction = ({person, referrals = 0, time = '', editState = false,
                                page,
                                onUpdateCustomer = () => {},
                                onErrorForm = (text) => {return '';},
                                onEditModeChanged = (mode) => {return false;} }) => {
    const count = useSelector(state => state.counter.count);
    const [editMode, setEditMode] = useState(false);
    const [formName, SetName] = useState('');
    const [formEmail, SetEmail] = useState('');
    const name = person?.profile?.name;
    const [error, SetError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [btnText, setBtnText] = useState('Save changes');
    const navigate = useNavigate();

    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';
    const shareUrl = `https://t.me/share/url?url=https://t.me/PicklezoneGameBot/PicklezoneGame` + code + `&text=Join Picklezone Game!`;

    const handleFileChange = (event) => {
        if (event?.target?.files[0]) {
            const file = event.target.files[0];
            setSelectedFile(file);
            uploadFile(file);
        }
    };

    useEffect(() => {
        setEditMode(editState);
    }, [editState]);
    useEffect(() => {

        if (person?.profile?.name) {
            SetName(person?.profile?.name);
            SetEmail(person?.profile?.email);
            setPreviewUrl(person?.profile?.image);
        }
    }, [editMode]);


    async function uploadFile(file) {
        const resp = await uploadImage(file);
        if (resp?.status) {
            setPreviewUrl(resp?.url);
        }
    }
    async function changePerson() {
        if (person?.profile) {
            const profile = {};
             profile.email = formEmail;
             profile.name = formName;
             if (previewUrl) {
                profile.image = previewUrl;
             }
            updatePerson(profile).then(resp => {
                onUpdateCustomer();
                setBtnText('Saved!');
                setTimeout(() => {
                    setBtnText('Save changes');
                    setEditMode(false);
                }, 200)
            }).catch(err => {
                console.log(err?.response?.data?.error);
                onErrorForm(err?.response?.data?.error);

            })

        }
    }


    const handleChangeName = (event) => {
        SetName(event.target.value);
    }
    const handleChangeEmail = (event) => {
        SetEmail(event.target.value);
    }

    function toggleEditMode(mode) {
        if (page !== 'main') {
            setEditMode(mode);
            onEditModeChanged(mode);
        } else {
          window.open(shareUrl);
        }
    }

    function onSaveChanges() {
        if (formEmail && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formEmail))) {
            onErrorForm('Email is not correct');
            return;
        }
        changePerson();
    }

    async function onCopyInviteLink() {
        const inviteLink = `https://t.me/share/url?url=https://t.me/PicklezoneGameBot/PicklezoneGame` + code;

        // await navigator.clipboard.writeText(copyLink);
        await navigator.clipboard.writeText(inviteLink);
    }


    function handleUploadImage() {
        console.log(document.getElementById('fileInput'));
        document.getElementById('fileInput').click();
    }

    function onEditSettings() {
        navigate(PATH_EARNED,  { state: { isOpened: true } });
    }

    return (
        <div className={`person-info ${page === 'main' ? 'container-main' : ''}`}>
            {person?.profile &&  <div className={`person-info__container  ${page === 'main' ? 'container-main' : ''}
            ${editMode ? 'editing' : ''}`}>
                {editMode &&
                    <div className={'person-info__close'}  onClick={() => toggleEditMode(false)}>
                    <CloseIcon/>
                </div>}
                {page === 'main' &&
                    <div className={'person-info__close'}  onClick={() => onEditSettings()}>
                        <SettingsIcon/>
                    </div>}
                {!editMode &&
                    <div className={`person-info__img-container`}>
                    <ImageComponent
                        imgClass={'person-info__img'}
                        alt={'person'}
                        src={!!person?.profile?.image ? person.profile.image : Person}
                    ></ImageComponent>
                    {/*<img  className={'person-info__img'}*/}
                    {/*    src={person?.profile?.image ? person?.profile?.image : Person} alt={'personImg'}/>*/}
                        {name && <p className={'person-info__text-normal'} >
                        <span className={'person-info__text-bold'}>
                            {name?.split(' ')[0]}</span>{' '}{ name?.split(' ')[1]}</p>}
                        {!name &&<p className={'person-info__text-normal'} >
                            <span className={'person-info__text-bold'}>{person?.customer?.name}</span></p>}
                    <a className={'person-info__link'}  style={{fontSize: '14px'}}
                       onClick={() => toggleEditMode(true)}>{page === 'main' ?  'INVITE FRIENDS' : 'Edit profile'}
                    </a>
                        {page !== 'main' && <a className={'person-info__link'} style={{color: '#000'}}
                           onClick={() => onCopyInviteLink()}>
                            Copy invitation link</a>}
                </div>}

                { editMode &&
                    <div style={{width: '100%'}}>
                     <div className={'form-for-profile-img-container'}>
                     <form className='form-for-profile-img'
                           style={{backgroundImage:
                             `url(${previewUrl ? previewUrl : person1})`, marginBottom: '30px'}}>
                        <input className={'person-info__img'}
                               id="fileInput"
                               type="file" accept="image/*"
                               onChange={handleFileChange} />
                         <p className={'person-info__link'} style={{marginTop: '10px'}}
                            onClick={() => handleUploadImage()}
                         >CHANGE AVATAR</p>
                     </form>
                     </div>
                     <form className='form-for-profile'>
                    <label className={'label-for-profile'}>NAME</label>
                    <input
                        type="text"
                        className={`input-for-profile ${error ? 'input-error-text' : ''} ${formName ? 'disabled' : ''}`}
                        placeholder="Name"
                        onChange={handleChangeName}
                        value={formName}
                        required
                    />
                  </form>
                    <form className='form-for-profile'>
                        <label className={'label-for-profile'}>EMAIL</label>
                        <input
                            type="text"
                            className={`input-for-profile ${error ? 'input-error-text' : ''} ${formName ? 'disabled' : ''}`}
                            placeholder="Email"
                            onChange={handleChangeEmail}
                            value={formEmail}
                            required
                        />
                    </form>

                        <button className={'btn-for-copy-links in-form'}
                                onClick={() => onSaveChanges()}>
                            {btnText}</button>
                </div>}
               {page === 'main' &&  (<div className={'person-info__cards'}>
               <div className={'person-info__card'}>
                   <StarsIcon/>
                  <p  className={'person-info__text'}>{parseNumbers(count)}</p>
               </div>
                 <div className={'person-info__card'}>
                   <FriendsIcon/>
                   <p  className={'person-info__text'}>{referrals}</p>
               </div>
               <div className={'person-info__card'}>
                   <WatchI/>
                   <p className={'person-info__text'}>{time}</p>
               </div>
             </div>)}
            </div>}
        </div>

    );
};

export default PersonInfoFunction;
