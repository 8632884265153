import React, {createRef, useEffect, useMemo, useRef, useState} from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";

import {useDispatch, useSelector} from "react-redux";
import {increment, setAmount} from "./counterSlice";

import {
    getCollectibles, getCustomerParams, getPerson,
    getLoyaltyData, getPointsLimits, registerInAffiliate, flySignup as flySignupApi
} from "../../logic/server/api";
import {useTelegram} from "../../hooks/useTelegram";
import AnimationScreen from "./AnimationScreen";
import {incrementBlinkByAmount} from "./blinkSlice";
import {APP_VERSION} from "../../logic/server/Variables";
import pickballAudio1 from '../../assets/audio/PICKLEBALL_PADDLE_1.mp3'
import pickballAudio2 from '../../assets/audio/PICKLEBALL_PADDLE_2.mp3'
import pickballAudio3 from '../../assets/audio/PICKLEBALL_PADDLE_3.mp3'
import pickballAudio4 from '../../assets/audio/PICKLEBALL_PADDLE_4.mp3'
import pickballAudio5 from '../../assets/audio/PICKLEBALL_PADDLE_5.mp3'
import winningSound from '../../assets/audio/winning_sound_1.mp3'



const Main = () => {
    // const navigate = useNavigate();
    const {webApp, user} = useTelegram();

    // const customer = useSelector(state => state.customer.customer);
    // const loyaltyData = useSelector(state => state.loyalty.loyalty);
    const dispatch = useDispatch();
    const [audioArray] = useState([new Audio(pickballAudio1), new Audio(pickballAudio2),
        new Audio(pickballAudio3), new Audio(pickballAudio4), new Audio(pickballAudio5)]);
    const [winningAudio] = useState(new Audio(winningSound));
    // const [audioElmSleep] = useState(new Audio(audioSleep));

    const [sleepMode, setSleepMode] = useState(false);
    const [sleepPoints, setSleepPoints] = useState([]);
    const [energy, setEnergy] = useState(5000);
    const [open, setOpen] = useState(false)
    const [collectibles, setCollectibles] = useState([]);
    const [customer, setCustomer] = useState({customer_id: null, token: null});
    const [person, setPerson] = useState({customer_id: null, token: null});
    const isStarted = useRef(false);
    const initData = webApp.initData;
    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';
    const userName = user ? user.username : '';

    const customer_id = new URLSearchParams(window.location.search)?.get('customer_id');
    const token = new URLSearchParams(window.location.search)?.get('token');
    const hash = initData ? new URLSearchParams(initData)?.get('hash') : '';

    const isAppOpenedSaved = useSelector(state => state.settings.isOpened);
    localStorage.setItem('openState', isAppOpenedSaved);

    useEffect(() => {
        const storedVersion = localStorage.getItem('app_version');
        if (storedVersion !== APP_VERSION) {
            console.log('clear_store');
            localStorage.setItem('time', '');
            localStorage.setItem('sleepTime', '');
            localStorage.setItem('timeOut', '');
            localStorage.setItem('openState', '');
            localStorage.setItem('app_version', APP_VERSION);
        }
        if ((initData || customer_id || localStorage.getItem('token')) && !isStarted.current) {
            getCustomer();
        } else if (!initData && !customer_id && !isStarted.current && !localStorage.getItem('token')) {
            setTimeout(() => {
                getCustomer();
            }, 3000);
        }
    }, []);

    async function getWallet() {
        const resp = await getPerson();
        if (resp && resp.status) {
            setPerson(resp?.person);
        }
    }

    async function flySignup() {
        const signupBody = {
            login: generateRandomString(8),
            password: generateRandomString(12),
            profile: {}
        };
        const flySignupResp = await flySignupApi(signupBody);
        if (flySignupResp && flySignupResp.status && flySignupResp.customer_id && flySignupResp.token) {
            localStorage.setItem('token', flySignupResp.token);
            localStorage.setItem('customer', flySignupResp.customer_id);
            setCustomer({ customer_id: flySignupResp.customer_id, token: flySignupResp.token });
            getAffiliateInfo({ customer_id: flySignupResp.customer_id, token: flySignupResp.token });
            getWallet(flySignupResp.token);
        }
    }

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };



    useEffect(() => {
        if (customer?.customer_id && customer?.token) {
            getCollectiblesItems();
        }
    }, [customer]);

    async function getCollectiblesItems(){
       const resp = await getCollectibles();
       if (resp?.status) {
          setCollectibles(resp.items);
       }
    }

    async function getAffiliateInfo(customerInfo) {
        const initDataURL = new URLSearchParams(initData);
        const referralCodeInitial = initDataURL?.get('start_param');

        getAffiliatesData(customerInfo);
        // if user go by referral link first time
        if (referralCodeInitial) {
            setTimeout(() => {
                getAffiliatesData(customerInfo);
            }, 10*1000);
        }

        const resp4 = await getPointsLimits(customerInfo.token);
        const remaining = resp4?.task?.completion?.limits?.reward?.per_user?.per_period?.remaining;
        if (remaining === 0) {
            setSleepMode(true);
            setEnergy(0);
            // localStorage.setItem('sleepMode', JSON.stringify(true));
        } else if (remaining) {
            setEnergy(remaining);
            // localStorage.setItem('sleepMode', JSON.stringify(false));
        }
    }

    async function getAffiliatesData(customerInfo) {
        const resp2 = await getLoyaltyData(customerInfo.customer_id, customerInfo.token);

        if (resp2 && resp2?.data?.reward?.loyalty_points) {
            const points = resp2?.data?.reward?.loyalty_points;
            localStorage.setItem('code', resp2?.data?.referral_code);

            dispatch(setAmount(points));
            localStorage.setItem('points', points);
        }
        if (!resp2 || !resp2?.data?.applied) {
            const resp3 = await registerInAffiliate(customerInfo.customer_id, customerInfo.token);
            if (resp3?.referral_code) {
                localStorage.setItem('code', resp3?.referral_code);
            }
        }
    }

    async function getCustomer() {
        const initDataURL = new URLSearchParams(initData);
        const referralCodeInitial = initDataURL?.get('start_param');
        isStarted.current = true;
        const params = new URLSearchParams(window.location.search);
        let customerInfo = {customer_id: params?.get('customer_id'), token: params?.get('token')};

        if ((!customerInfo.customer_id || !customerInfo.token) && initData) {
            const resp = await getCustomerParams(initData, referralCodeInitial);
            if (resp?.status) {
               customerInfo = { customer_id: resp.customer?.customer_id, token: resp.customer?.token };
            }
        }
        if (!initData && (!customerInfo.customer_id || !customerInfo.token) && !localStorage.getItem('token')) {
            console.log('22')
            flySignup();
        }

        if ((customerInfo && customerInfo.customer_id && customerInfo.token) || localStorage.getItem('token')) {
            if (customerInfo?.customer_id) {
                localStorage.setItem('token', customerInfo?.token);
                localStorage.setItem('customer', customerInfo?.customer_id);

                const resp5 = await getCollectibles();
                if (resp5?.status) {
                    setCollectibles(resp5.items);
                }
            }

            // for localhost
            if (!customerInfo.customer_id && localStorage.getItem('token')) {
                customerInfo = {customer_id: localStorage.getItem('customer'), token: localStorage.getItem('token')};
            }
            setCustomer(customerInfo);


            getAffiliateInfo(customerInfo);
            getWallet();
        }
    }


return <AnimationScreen collectibles={collectibles} person={person} audioArray={audioArray} winningAudio={winningAudio}></AnimationScreen>
};

export default Main;
