import React from "react";
// import play from "../../../assets/play.svg";
// import friends from "../../../assets/friends.svg";
// import tasks from "../../../assets/tasks.svg";
// import buyCoins from "../../../assets/buy-coins.svg";
import {ReactComponent as PlayIcon} from '../../../assets/game-page.svg';
import {ReactComponent as TasksIcon} from '../../../assets/tasks-page.svg';
import {ReactComponent as FriendsIcon} from '../../../assets/friends-page.svg';
import {ReactComponent as BuyCoinsIcon} from '../../../assets/buy-coins.svg';
import {ReactComponent as RedeemIcon} from '../../../assets/redeem-page.svg';
import {ReactComponent as BagIcon} from '../../../assets/bag-icon.svg';
import {ReactComponent as CommunityIcon} from '../../../assets/community.svg';
import {ReactComponent as GroupIcon} from '../../../assets/group-icon.svg';
import {ReactComponent as HeartIcon} from '../../../assets/heart-icon.svg';
import {ReactComponent as StopWatch} from '../../../assets/stopwatch.svg';
import {
    PATH_CLAIM,
    PATH_COLLECTIBLES,
    PATH_Community,
    PATH_EARNED,
    PATH_MAIN,
    PATH_REDEEM,
    PATH_TASKS
} from "../../../constants/Paths";
import {useNavigate} from "react-router-dom";
import ImpactOccurredHapticFeedback from "../haptic/ImpactOccurredHapticFeedback";
import {useTelegram} from "../../../hooks/useTelegram";
import {parseNumbers} from "../../../logic/server/api";

const FooterFunction = ({currentPage, isGameActive = false, isRewardScreen= false,
                            timeOutState = false, formattedTime = '', referrals = 0,
                            onPlay = () => {}, onStop = () => {} }) => {
    const {webApp, executeMethod} = useTelegram();
    // console.log(webApp?.HapticFeedback);
    const points = localStorage.getItem('points');
    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';

    const navigate = useNavigate();
    const successLink =  `https://t.me/share/url?url=https://t.me/PicklezoneGameBot/PicklezoneGame&text=I have ${parseNumbers(points)} DINKS! Join Picklezone Game!`;
    const inviteLink = `https://t.me/PicklezoneGameBot/PicklezoneGame` + code;
    const onImpact = () => {
        try {
            webApp?.HapticFeedback?.impactOccurred('medium');
        } catch (err) {
            console.log('no impactOccurred')
        }
    }
    const onNotify = () => {
        try {
            webApp?.HapticFeedback?.notificationOccurred('success');
        } catch (err) {
            console.log('no notificationOccurred')
        }
    }

    async function onCopySuccessLink() {
        // await navigator.clipboard.writeText(copyLink);
        await window.open(successLink, '_blank');
    }
    async function onCopyInviteLink() {
        // await navigator.clipboard.writeText(copyLink);
        await navigator.clipboard.writeText(inviteLink);
    }


    return (
        <div style={{zIndex: 1000}}>
            <div className={`footer-container ${timeOutState ? 'footer-container__light' : ''}`}>

                { currentPage === 'community' &&
                    <div className={'picklezone-forms-container'}>
                        {/*<div className={'blue-text-name-roboto profile-link'}*/}
                        {/*     onClick={() => navigate(PATH_PROFILE)}>My profile</div>*/}
                        <button className={'btn-for-copy-links'}
                                onClick={() => onCopySuccessLink()}>
                            Share your success!</button></div>
                }
                {/*{ currentPage === 'earnings' &&*/}
                {/*    <div className={'picklezone-forms-container'}>*/}
                {/*        <button className={'btn-for-copy-links'}*/}
                {/*                onClick={() => onCopyInviteLink()}>*/}
                {/*            Copy invitation link</button></div>*/}
                {/*}*/}
              {currentPage === 'main' && isGameActive && !isRewardScreen && !timeOutState && (
               <div className={`footer__controls-container ${timeOutState ? 'disabled' : ''}`}>
                <div  className='footer__control'>
                    <GroupIcon className={'footer__control-icon'}></GroupIcon>
                    <p>{referrals}</p>
                </div>
                <div className={'footer__stop_btn'} onClick={onStop}>
                    <div className={'footer__stop_btn-center'}></div>
                </div>
                <div  className='footer__control'>
                    <StopWatch
                        className={'footer__control-icon'}></StopWatch>
                    <p>{formattedTime}</p>
                </div>
            </div>
            )}
                {currentPage === 'main' && !isGameActive && !isRewardScreen && !timeOutState && (
               <div className='footer__controls-container'>
                   <div  className='footer__controls-start-btn'
                         onClick={onPlay} >Play now</div>
               </div>
                )}


            <div className='footer__cards-container'>

                <div className={`footer__cards ${currentPage === 'main' ? 'footer__cards--active' : ''}`}
                     onClick={() => navigate(PATH_MAIN)}>
                    <PlayIcon className={'footer__icon'}/>
                    <div className={`forms__bottom-results-container forms__bottom-icon--day-mode`}>
                        {/*<div className='footer__text text-regular'>Play</div>*/}
                    </div>
                </div>

                <div className={`footer__cards ${currentPage === 'tasks' ? 'footer__cards--active' : ''} `}
                     onClick={() => navigate(PATH_TASKS)}>
                    <TasksIcon  className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Tasks</div>*/}
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'collectibles' ? 'footer__cards--active' : ''}  `}
                     onClick={() => navigate(PATH_COLLECTIBLES)}>
                    <BagIcon  className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Tasks</div>*/}
                    </div>
                </div>
                
                
                <div className={`footer__cards ${currentPage === 'earnings' ? 'footer__cards--active' : ''} `}
                     onClick={() => navigate(PATH_EARNED)}>
                    <FriendsIcon className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Friends</div>*/}
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'community' ? 'footer__cards--active' : ''} `}
                     onClick={() => navigate(PATH_Community)}>
                    {/*<CommunityIcon className={'footer__icon'}/>*/}
                    <CommunityIcon className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Friends</div>*/}
                    </div>
                </div>
                <div className={`footer__cards ${currentPage === 'redeem' ? 'footer__cards--active' : ''} disabled`}
                     onClick={() => navigate(PATH_CLAIM)}>
                    <RedeemIcon className={'footer__icon'}/>
                    <div className='forms__bottom-results-container'>
                        {/*<div className='footer__text text-regular'>Redeem</div>*/}
                    </div>
                </div>
              </div>
            </div>
        </div>
    );
};

export default FooterFunction;
